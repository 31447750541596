.refund-container {
  height: 100%;
  display: grid;
  .payment-action {
    margin-top: 3.2rem;
    .btn {
      border-radius: 3.6rem;
    }
  }
  .refund-block {
    display: flex;
    flex-direction: column;
  }
  .card-block__body {
    max-width: 51.2rem;
    width: 100%;
    margin: 0 auto;
  }
  .card-block__content {
    padding: 0;
    display: grid;
    gap: 2.2rem;
  }

  .refund-card,
  .card-block__body,
  .payment-action, .refund-fee {
    @include mobile {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
  .result {
    padding: 1.4rem 0;
    max-width: 51.2rem;
    width: 100%;
    margin: 0 auto;
    margin-top: 1.6rem;
  }
  .result-item {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
  .result-item__title,
  .result-item__value {
    font: var(--Body2_reg_14);
    &.is-total {
      font: var(--Body3_reg_16);
    }
  }
}
.refund-title {
  font: var(--T2_med_32);
  margin-bottom: 2.4rem;
  text-align: center;
}
.refund-card {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  text-align: center;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  svg {
    width: 7.2rem;
    height: 7.2rem;
    margin: 0 auto;
  }
  &__title {
    font: var(--Body3_reg_16);
  }
  &__amount {
    font: var(--T2_med_32);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
  }
}

#root.isPageMode ~ div .rc-tooltip.refund-select-network {
  max-width: 51.2rem;
  .rc-tooltip-content {
    width: 100%;
    max-width: 100%;
  }
}
